import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

const ProgressIndicator = ({ status, progress, number }) => {
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: "70px",
          right: "25px",
          width: "250px",
          paddingTop: "10px",
          paddingRight: "15px",
          paddingBottom: "10px",
          paddingLeft: "15px",
          backgroundColor: "#ffffff",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          color: "blue",
          zIndex: 1000,
        }}
      >
        <Typography
          color="73A5C6"
          variant="body2"
          mb="10px"
          letterSpacing="0.5px"
          sx={{
            backgroundColor: "#ffffff",
          }}
        >
          {status}: {progress}%
        </Typography>
        <p
          style={{
            color: "#333333",
            fontSize: "13px",
            margin: 0,
            backgroundColor: "#ffffff",
          }}
        >
          {number}
        </p>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: "10px",
            borderRadius: "1px",
            mt: "20px",
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#1E3F66",
            },
          }}
        />
      </Box>
    </>
  );
};

export default ProgressIndicator;
