import "../components/Header.css";

import appLogo from "../assets/EZ-BYTE-black.png";
import logout from "../assets/logout.png";
import notification from "../assets/notificationt.png";
import logo1 from "../assets/logo1.jpg";
// import logo2 from "../assets/logo2.jpg";
import logo3 from "../assets/logo3.jpg";
import logo4 from "../assets/logo4.jpg";
// import logo5 from "../assets/logo5.jpg";
import favorites from "../assets/favorites.png";
import profile from "../assets/user.png";
import { useNavigate } from "react-router-dom";

import { Drawer } from "antd";
import { useState } from "react";

import {
  FaBalanceScale,
  FaBars,
  FaEnvelope,
  FaFileAlt,
  FaFileSignature,
  FaFolder,
  FaGavel,
  FaStar,
  FaTasks,
  FaUpload,
  FaUser,
} from "react-icons/fa";

export default function Header({ email, onLogout, name, photoURL }) {
  const [isVisible, setIsVisible] = useState(false);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const navigate = useNavigate();
  function handelLogoClick() {
    navigate("/home");
  }

  function handelAttorneyScreen() {
    navigate("/attorney");
  }

  function handelDrawer() {
    setIsVisible(true);
  }
  function handelDrawerClose() {
    setIsVisible(false);
  }

  function toggleDrawer() {
    setIsDrawerVisible(!isDrawerVisible);
  }

  return (
    <header className="header">
      <div className="header-logo">
        <div className="hamburger-menu" onClick={toggleDrawer}>
          <FaBars />
        </div>
        <img src={logo1} alt="appLogo" onClick={handelLogoClick} />
      </div>

      <div className="header-search">
        <input type="text" placeholder="Search..." />
      </div>

      <div className="header-icons">
        <img src={notification} alt="notification" />
        <img src={favorites} alt="favorites" />
      </div>

      <div className="header-user">
        <img src={profile} alt="Logout" onClick={handelDrawer} />
      </div>
      {isVisible && (
        <Drawer open={isVisible} onClose={handelDrawerClose} closable={false}>
          <div className="drawer-content">
            <p className="drawer-email">{email}</p>
            <img
              className="drawer-profile-pic"
              src={photoURL || appLogo}
              alt="ProfilePic"
            />
            <p className="drawer-username">Hi, {name}!</p>
            <hr className="drawer-divider" />
            <div className="drawer-logout" onClick={onLogout}>
              <img src={logout} alt="Logout" className="drawer-logout-icon" />
              <span className="drawer-logout-text">Logout</span>
            </div>
          </div>
        </Drawer>
      )}

      {isDrawerVisible && (
        <Drawer
          open={isDrawerVisible}
          onClose={toggleDrawer}
          placement="left"
          style={{ width: "250px" }}
          closable={false}
        >
          <div className="drawer-body">
            <aside>
              <ul className="drawer-list">
                <li className="drawer-list-item">
                  <FaUpload className="icon" />
                  <span className="drawer-text">Upload</span>
                </li>
                <li className="drawer-list-item">
                  <FaUser className="icon" />
                  <span className="drawer-text">Recent Files</span>
                </li>
                <li className="drawer-list-item">
                  <FaStar className="icon" />
                  <span className="drawer-text">Favorites</span>
                </li>
                <li className="drawer-list-item">
                  <FaTasks className="icon" />
                  <span className="drawer-text">Tasks</span>
                </li>
                <li className="drawer-list-item">
                  <FaFileAlt className="icon" />
                  <span className="drawer-text">Documents</span>
                </li>
                <li className="drawer-list-item">
                  <FaEnvelope className="icon" />
                  <span className="drawer-text">Letters</span>
                </li>
                <li className="drawer-list-item">
                  <FaFolder className="icon" />
                  <span className="drawer-text">My Folders</span>
                </li>
                <li className="drawer-list-item">
                  <FaFileSignature className="icon" />
                  <span className="drawer-text">Police Report</span>
                </li>
                <li className="drawer-list-item">
                  <FaBalanceScale className="icon" />
                  <span className="drawer-text">To Adjuster</span>
                </li>
                <li className="drawer-list-item" onClick={handelAttorneyScreen}>
                  <FaGavel className="icon" />
                  <span className="drawer-text">To Attorney</span>
                </li>
              </ul>
            </aside>
          </div>
        </Drawer>
      )}
    </header>
  );
}
