import { useUserDetails } from "../customHook/userDetails";
import axios from 'axios';
export const EMAIL_API_HOST_URL = process.env.REACT_APP_EMAIL_API_URL;

export default async function EmailService(emailData, accessToken) {
  let files = emailData.selectedFiles
    .map((file, index) => `${index + 1}) ${file.name.substring(file.name.lastIndexOf("/") + 1)}`)
    .join("<br>");

  let fileLocations = emailData.selectedFiles.map((file) =>
    file.path.startsWith("/") ? file.path.slice(1) : file.path
  );

  // API POST CALL
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${accessToken}`
  };

  const emailPayload = {
    name: emailData.emailName,
    to: emailData.emailName.split(","),
    subject: emailData.subject,
    body: `${emailData.body}<br/><br/> ${emailData.selectedFiles.length} file(s) attached:<br>${files}`,
    fileLocations: fileLocations,
  };

  try {
    const response = await axios.post(
      `${EMAIL_API_HOST_URL}/6f633b9a-184a-4fbf-bff8-0801ddc0279e`,
      emailPayload,
      { headers: headers }
    );

    if (response.status === 200) {
      const result = response.data;
      // console.log(result);
      return true;
    } else {
      // console.error('Request failed with status:', response.status);
      return false;
    }
  } catch (error) {
    // console.error('Request error:', error);
    return false;
  }
}