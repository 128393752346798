import { useEffect, useRef, useState } from "react";

import "../newComponent/FolderModal.css";

function CreateFolderModal({ isOpen, onClose, onSubmit, currentFolderPath }) {
  const [folderName, setFolderName] = useState("");

  const inputRef = useRef(null); // Reference for the input element

  // Focus the input field when the modal opens
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const handleSubmit = () => {
    if (folderName.trim() === "") {
      alert("Folder name cannot be empty.");
      return;
    }
    onSubmit(folderName);
    setFolderName("");
    onClose();
  };

  const handelKeyDown = (event) => {
    if (event.key === "Enter") {
      if (isOpen) {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit();
      }
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  if (!isOpen) return null;

  const handelChange = (e) => {
    const inputValue = e.target.value;

    if (!inputValue.includes("/")) {
      setFolderName(inputValue);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Folder Name</h3>
        <input
          ref={inputRef}
          type="text"
          placeholder="Enter folder name"
          value={folderName}
          // onChange={(e) => setFolderName(e.target.value)}
          onChange={handelChange}
          onKeyDown={handelKeyDown}
        />
        <div className="modal-buttons">
          <button className="cancel-button" onClick={onClose}>
            Cancel
          </button>
          <button className="ok-button" onClick={handleSubmit}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateFolderModal;
