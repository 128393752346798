// export const allowedExtensions = ["jpg"];

export const allowedExtensions = [
  "jpg",
  "jpeg",
  "png",
  "tiff",
  "gif",
  "bmp",
  "iiq",
  "3fr",
  "dcr",
  "k25",
  "kdc",
  "cr2",
  "nef",
  "crw",
  "erf",
  "mef",
  "mos",
  "nef",
  "nrw",
  "orf",
  "pef",
  "rw2",
  "arw",
  "srf",
  "sr2",
  "heif",
  "mp3",
  "mp4",
  "hevc",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "csv",
  "ppt",
  "pptx",
  "txt",
  "pdf",
];
