import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: '',
  additionalPageInfo: {},
  currentFolder:'',
  selectedItems: [],
  currentItems: [],
  isHome: true,
  isSubFolder: false,
  isRecentResults: false,
  isSearchResults: false,
  isFavoritesResults: false,
  isTrashResults: false,
};

const pageInfoSlice = createSlice({
  name: 'pageInfo',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
      switch(action.payload){
        case 'home':
          state.isHome = true;
          state.isSubFolder = false;
          state.isRecentResults = false;
          state.isSearchResults = false;
          state.isFavoritesResults = false;
          state.isTrashResults = false;
          break;
        case 'recent':
          state.isHome = false;
          state.isRecentResults = true;
          state.isSearchResults = false;
          state.isFavoritesResults = false;
          state.isTrashResults = false;
          break;
        case 'search':
          state.isHome = false;
          state.isRecentResults = false;
          state.isSearchResults = true;
          state.isFavoritesResults = false;
          state.isTrashResults = false;
          break;
        case 'favorites':
          state.isHome = false;
          state.isRecentResults = false;
          state.isSearchResults = false;
          state.isFavoritesResults = true;
          state.isTrashResults = false;
          break;
        case 'trash':
          state.isHome = false;
          state.isRecentResults = false;
          state.isSearchResults = false;
          state.isFavoritesResults = false;
          state.isTrashResults = true;
          break;
        case 'subFolder':
          state.isHome = false;
          state.isSubFolder = true;
          state.isRecentResults = false;
          state.isSearchResults = false;
          state.isFavoritesResults = false;
          state.isTrashResults = false;
          break;
        default:
          state.isHome = true;
          state.isSubFolder = false;
          state.isRecentResults = false;
          state.isSearchResults = false;
          state.isFavoritesResults = false;
          state.isTrashResults = false;
          break;
      }
    },
    setAdditionalPageInfo(state, action) {
      state.additionalPageInfo = action.payload;
    },
  },
});

export const { setCurrentPage, setAdditionalPageInfo } = pageInfoSlice.actions;
export default pageInfoSlice.reducer;