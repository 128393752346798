import { FaAddressBook, FaClock, FaHistory } from "react-icons/fa";
import React from "react";

import "../components/SideBar.css";
import { useNavigate } from "react-router-dom";

export default function SideBar({ onUploadClick }) {
  const navigate = useNavigate();

  function handelHomeButton() {
    navigate("/home");
  }
  return (
    <div className="sidebar">
      <button className="upload-button" onClick={onUploadClick}>
        Upload
      </button>
      <div className="menu-item" onClick={handelHomeButton}>
        <FaHistory className="icon" />
        {/* <span>Home</span> */}
        <span>History</span>
      </div>
      <div className="menu-item">
        <FaClock className="icon" />
        <span>Recent Files</span>
      </div>
      <div className="menu-item">
        <FaAddressBook className="icon" />
        <span>Contacts</span>
      </div>
    </div>
  );
}
