import "../newComponent/Header.css";

import logo1 from "../assets/logo1.jpg";
import profile from "../assets/user.png";
import Bell from "../assets/bell.png";
import Settings from "../assets/settings.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import CommonOverlay from "../newComponent/CommonOverlay";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";

import PolicyOutlinedIcon from "@mui/icons-material/PolicyOutlined";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";

import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";

import appLogo from "../assets/EZ-BYTE-black.png";
import SearchInput from "../components/SearchInput";
import {
  Business,
  CloudOutlined,
  Edit,
  Folder,
  LockClockOutlined,
  PersonAddAlt,
  Reviews,
  ReviewsOutlined,
} from "@mui/icons-material";

import axios from "axios";
import { COMPANY_LOGO } from "../components/APP_LOGO/app_logo";
import UploadOverlay from "../components/UploadOverlay";
import BusinessProfile from "../components/Business_Settings/BusinessProfile";

// const GET_USER_API =
//   "https://devapi.ez-byte.com:5050/groupadmin/api/getGroupUsers";

const GET_USER_API = process.env.REACT_APP_GET_USER_API_URL;
// const GET_USER_STORAGE = "https://devapi.ez-byte.com:5002/calcSize";
const GET_USER_STORAGE = process.env.REACT_APP_GET_USER_STORAGE_API_URL;

function Header1({
  email,
  onLogout,
  name,
  photoURL,
  files,
  handleFileSearch,
  accessToken,
  handelLogoClick,
}) {
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isBusinessSettingsOpen, setIsBusinessSettingsOpen] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const [myData, setMyData] = useState();
  const [isFeedbackOverlayOpen, setIsFeedbackOverlayOpen] = useState(false);
  const [storageData, setStorageData] = useState({
    maxAllowedCapacity: 0.0,
    actualSize: 0.0,
  });

  function LogoClickHandeler() {
    // navigate("/home");
    handelLogoClick();
  }

  const headers = {
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${accessToken}`,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const respose = await axios.get(GET_USER_API, { headers });
        const data = await respose.data;

        setMyData(data);

        console.log(data);
      } catch (error) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchStorage = async () => {
      try {
        const storageResponse = await axios.get(GET_USER_STORAGE, { headers });
        const storageData = await storageResponse.data;

        setStorageData(storageData);
        console.log(storageData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchStorage();
  }, [isSettingsOpen]);

  console.log(storageData);

  // const openModal = () => setIsModalOpen(true);
  const openModal = () => {
    setIsModalOpen(true);

    setIsSettingsOpen(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsSettingsOpen(true);
  };

  function toggleDrawer() {
    // console.log("Comming In Profile");
    if (isSettingsOpen) {
      setIsSettingsOpen(false);
      // console.log("Comming Middel profile");
    }
    setIsDrawerOpen((prev) => !prev);
    // console.log("Comming End profile");
  }

  function toggleSettings() {
    // console.log("Comming In Setting");

    setIsDrawerOpen(false);
    // console.log("Comming middel setting");

    setIsSettingsOpen((prev) => !prev);
    // console.log("Comming middle setting");
  }

  const openDeleteConfirm = () => {
    setIsDeleteConfirmOpen(true);
  };
  const closeDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  // const usedStorage = 350;
  const totalStorage = storageData.maxAllowedCapacity;
  const usedStorage = storageData.actualSize;
  // const totalStorage = storageData.maxAllowedCapacity;

  const percentageUsed = (usedStorage / totalStorage) * 100;

  const handleFeedback = async (e) => {
    setIsFeedbackOverlayOpen(true);
  };
  const closeFeedbackOverLay = () => {
    setIsFeedbackOverlayOpen(false);
  };

  const BusinessSettingsHandler = () => {
    setIsBusinessSettingsOpen(true);
    setIsDrawerOpen((prev) => !prev);
  };

  const CloseBusinessSettingsHandler = () => {
    setIsBusinessSettingsOpen(false);
    // setIsDrawerOpen((prev) => !prev);
  };

  return (
    <>
      <header className="header1">
        <div className="header1-logo">
          <img
            src={COMPANY_LOGO}
            alt="Company Logo"
            onClick={LogoClickHandeler}
            draggable="false"
            className="company-logo"
          />
          <span>Drive</span>
        </div>

        <div className="search">
          <SearchInput files={files} handleFileSearch={handleFileSearch} />
        </div>

        <div className="header1-icons">
          <span>
            {/* <img
              className="left-icon"
              src={Bell}
              alt="NotificationIcon"
              draggable="false"
            /> */}
            {/* <div style={{ marginTop: "6px", cursor: "pointer" }} title="Clock">
              <ReviewsOutlined />
            </div> */}
            <div
              className="tooltip-container"
              style={
                {
                  // marginTop: "6px",
                  // cursor: "pointer",
                }
              }
              onClick={handleFeedback}
            >
              <ReviewsOutlined />
              <span className="tooltip-text">Provide feedback</span>
            </div>

            <img
              className="left-icon"
              src={Settings}
              alt="SetingsIcon"
              draggable="false"
              onClick={toggleSettings}
            />
          </span>
          <span>
            <img
              src={profile}
              alt="Profile Icon"
              onClick={toggleDrawer}
              draggable="false"
            />
          </span>
        </div>
      </header>

      {/* Settings Drawer  */}
      <div className={`custom-drawer-setting ${isSettingsOpen ? "open" : ""}`}>
        <div className="drawer-options1 drawer-options-setting">
          <div
            className="drawer-option1 drawer-option-setting"
            onClick={openModal}
          >
            <GroupsOutlinedIcon className="drawer-icon-setting" />
            <div className="drawer-texts-setting">
              <span className="drawer-text-primary-setting">
                User management
              </span>
              <span className="drawer-text-secondary-setting">
                Add users, groups, and manage access request.
              </span>
            </div>
          </div>

          <div className="drawer-option1 drawer-option-setting">
            <ReceiptOutlinedIcon className="drawer-icon-setting" />
            <div className="drawer-texts-setting">
              <span className="drawer-text-primary-setting">Billing</span>
              <span className="drawer-text-secondary-setting">
                Update your billing details, manage your subscriptions and more.
              </span>
            </div>
          </div>
          {/* Add the storage functionality directly here */}
          <div className="storage-container">
            <div className="storage-text">
              <CloudOutlined className="cloud-icon" /> Storage Overview
            </div>
            <div className="storage-bar">
              <div
                className="storage-bar-fill"
                style={{
                  width: `${percentageUsed}%`,
                }}
              ></div>
            </div>
            <div className="storage-info">
              {usedStorage.toFixed(2)} GB of {totalStorage} GB used
            </div>
            <button
              className="storage-upgrade-btn"
              onMouseEnter={(e) => {
                e.target.classList.add("hover");
              }}
              onMouseLeave={(e) => {
                e.target.classList.remove("hover");
              }}
            >
              Get more storage
            </button>
          </div>
        </div>
      </div>

      {/* -------------------------------- */}

      {/* Custom Drawer  */}
      <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
        <div className="drawer-content1">
          <img
            src={photoURL || appLogo}
            alt="UserImage"
            className="profile-photo"
          />

          <div className="name-div1">
            <p>Hi! {name}</p>
            <span>{email}</span>
          </div>
        </div>

        <div className="drawer-options1">
          <div className="drawer-option1" onClick={BusinessSettingsHandler}>
            <PermIdentityOutlinedIcon />
            <span>Business Settings</span>
          </div>

          <div className="drawer-option1">
            <DarkModeOutlinedIcon />
            <span>Apperence</span>
          </div>

          <div className="drawer-option1">
            <PrivacyTipOutlinedIcon />
            <span>Privacy & Security</span>
          </div>
          <div className="drawer-option1">
            <ManageAccountsOutlinedIcon />
            <span>Manage Accounts</span>
          </div>

          <div className="drawer-option1">
            <HelpOutlineOutlinedIcon />
            <span>Help</span>
          </div>

          <div className="drawer-option1">
            <PolicyOutlinedIcon />
            <span>Privacy Policy</span>
          </div>

          <div className="logout-btn1" onClick={onLogout}>
            <LogoutOutlinedIcon />
            <button>Logout</button>
          </div>
        </div>
      </div>

      {/* Overlay when drawer is open */}

      {isDrawerOpen && (
        <div className="drawer-overlay" onClick={toggleDrawer}></div>
      )}
      {isSettingsOpen && (
        <div className="drawer-overlay" onClick={toggleSettings}></div>
      )}

      {isFeedbackOverlayOpen && (
        <CommonOverlay 
        isOpen={isFeedbackOverlayOpen} 
        onClose={closeFeedbackOverLay} 
        size="medium"
        title="Feedback">
          <UploadOverlay
          accessToken={accessToken}
          onClose={closeFeedbackOverLay}
          isOpen={isFeedbackOverlayOpen}
          isUploadForFeedback={"true"}
          />
        </CommonOverlay>
        // <UploadOverlay
        //   accessToken={accessToken}
        //   onClose={closeFeedbackOverLay}
        //   isOpen={isFeedbackOverlayOpen}
        //   isUploadForFeedback={"true"}
        // />
      )}
      {isBusinessSettingsOpen && (
        <BusinessProfile
          CloseBusinessSettingsHandler={CloseBusinessSettingsHandler}
        />
      )}
      {/* Modal For User Management */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Manage Users</h2>
              <button className="close-button" onClick={closeModal}>
                &times;
              </button>
            </div>

            <button className="add-user-btn">
              <span>
                Add User <PersonAddAlt />
              </span>
            </button>

            {/* Table Section */}
            <div className="table-wrapper">
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Remove</th>
                      <th className="expand">GroupUserEmail</th>
                      <th>MaxAllowedCapacity</th>
                      <th>CurrentUse</th>
                      <th>Status</th>
                      <th>Active</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {myData.data.map((myUser, index) => (
                      <tr key={index}>
                        <td>
                          <DeleteOutlineOutlinedIcon
                            style={{ width: "15px", background: "white" }}
                            onClick={() => openDeleteConfirm(index)}
                          />
                        </td>
                        <td>{myUser.groupUserEmail}</td>
                        <td>
                          {
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                background: "white",
                              }}
                            >
                              {myUser.maxAllowedCapacity}
                              <Edit
                                style={{ width: "15px", background: "white" }}
                              />
                            </span>
                          }
                        </td>
                        <td>{myUser.currentUse}</td>
                        <td>{myUser.status}</td>
                        <td>
                          <span
                            className={`label ${
                              myUser.active === true ? "label-live" : "label-no"
                            }`}
                          >
                            {myUser.active === true ? "active" : "Inactive"}
                          </span>
                        </td>
                        <td>{myUser.role}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="delete-confirm-overlay" onClick={closeDeleteConfirm}>
          <div
            className="delete-confirm-container"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Are you sure you want to delete this User?</h3>
            <button className="btn-yes" onClick={closeDeleteConfirm}>
              Proceed
            </button>
            <button className="btn-no" onClick={closeDeleteConfirm}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Header1;

// OLD ONE

// function Header1({ email, onLogout, name, photoURL, files, handleFileSearch }) {
//   const navigate = useNavigate();

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   function handelLogoClick() {
//     navigate("/home");
//   }

//   function toggleDrawer() {
//     setIsDrawerOpen((prev) => !prev);
//   }

//   return (
//     <>
//       <header className="header1">
//         <div className="header1-logo">
//           <img
//             src={logo1}
//             alt="Company Logo"
//             onClick={handelLogoClick}
//             style={{ borderRadius: "5px" }}
//             draggable="false"
//           />
//           <span>Drive</span>
//         </div>

//         <div>
//           <SearchInput files={files} handleFileSearch={handleFileSearch} />
//         </div>

//         <div className="header1-icons">
//           <span>
//             <img
//               className="left-icon"
//               src={Bell}
//               alt="NotificationIcon"
//               draggable="false"
//             />
//             <img
//               className="left-icon"
//               src={Settings}
//               alt="SetingsIcon"
//               draggable="false"
//               onClick={toggleDrawer}
//             />
//           </span>
//           <span>
//             <img
//               src={profile}
//               alt="Profile Icon"
//               onClick={toggleDrawer}
//               draggable="false"
//             />
//           </span>
//         </div>
//       </header>

//       {/* Custom Drawer  */}
//       <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
//         <div className="drawer-content1">
//           <img
//             src={photoURL || appLogo}
//             alt="UserImage"
//             className="profile-photo"
//           />

//           <div className="name-div1">
//             <p>Hi! {name}</p>
//             <span>{email}</span>
//           </div>
//         </div>

//         <div className="drawer-options1">
//           <div className="drawer-option1">
//             <PermIdentityOutlinedIcon />
//             <span>Profile Settings</span>
//           </div>

//           <div className="drawer-option1">
//             <DarkModeOutlinedIcon />
//             <span>Apperence</span>
//           </div>

//           <div className="drawer-option1">
//             <PrivacyTipOutlinedIcon />
//             <span>Privacy & Security</span>
//           </div>
//           <div className="drawer-option1">
//             <ManageAccountsOutlinedIcon />
//             <span>Manage Accounts</span>
//           </div>

//           <div className="drawer-option1">
//             <HelpOutlineOutlinedIcon />
//             <span>Help</span>
//           </div>

//           <div className="drawer-option1">
//             <PolicyOutlinedIcon />
//             <span>Privacy Policy</span>
//           </div>

//           <div className="logout-btn1" onClick={onLogout}>
//             <LogoutOutlinedIcon />
//             <button>Logout</button>
//           </div>
//         </div>
//       </div>
//       {/* Overlay when drawer is open */}
//       {isDrawerOpen && (
//         <div className="drawer-overlay" onClick={toggleDrawer}></div>
//       )}
//     </>
//   );
// }

// export default Header1;

// function Header1({ email, onLogout, name, photoURL, files, handleFileSearch }) {
//   const navigate = useNavigate();

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//   function handelLogoClick() {
//     navigate("/home");
//   }

//   function toggleDrawer() {
//     console.log("Comming In Profile");
//     if (isSettingsOpen) {
//       setIsSettingsOpen(false);
//       console.log("Comming Middel profile");
//     }
//     setIsDrawerOpen((prev) => !prev);
//     console.log("Comming End profile");
//   }

//   // function toggleSettings() {
//   //   console.log("Comming In Setting");
//   //   if (isDrawerOpen) {
//   //     setIsDrawerOpen(false);
//   //     console.log("Comming middel setting");
//   //   }
//   //   setIsSettingsOpen((prev) => !prev);
//   //   console.log("Comming middle setting");
//   // }
//   function toggleSettings() {
//     console.log("Comming In Setting");

//     setIsDrawerOpen(false);
//     console.log("Comming middel setting");

//     setIsSettingsOpen((prev) => !prev);
//     console.log("Comming middle setting");
//   }

//   return (
//     <>
//       <header className="header1">
//         <div className="header1-logo">
//           <img
//             src={logo1}
//             alt="Company Logo"
//             onClick={handelLogoClick}
//             style={{ borderRadius: "5px" }}
//             draggable="false"
//           />
//           <span>Drive</span>
//         </div>

//         <div>
//           <SearchInput files={files} handleFileSearch={handleFileSearch} />
//         </div>

//         <div className="header1-icons">
//           <span>
//             <img
//               className="left-icon"
//               src={Bell}
//               alt="NotificationIcon"
//               draggable="false"
//             />
//             <img
//               className="left-icon"
//               src={Settings}
//               alt="SetingsIcon"
//               draggable="false"
//               onClick={toggleSettings}
//             />
//           </span>
//           <span>
//             <img
//               src={profile}
//               alt="Profile Icon"
//               onClick={toggleDrawer}
//               draggable="false"
//             />
//           </span>
//         </div>
//       </header>

//       {/* Settings Drawer  */}
//       <div className={`custom-drawer-setting ${isSettingsOpen ? "open" : ""}`}>
//         <div className="drawer-options1 drawer-options-setting">
//           <div className="drawer-option1 drawer-option-setting">
//             <PermIdentityOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">
//                 Profile Settings
//               </span>
//               <span className="drawer-text-secondary-setting">HHHHH</span>
//             </div>
//           </div>

//           <div className="drawer-option1 drawer-option-setting">
//             <DarkModeOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">Appearance</span>
//               <span className="drawer-text-secondary-setting">
//                 kmcsmckdmlcsdlc,kkkkkkllllllllllllllllllllnlkkijnjnjnjhnjnjnk
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* -------------------------------- */}

//       {/* Custom Drawer  */}
//       <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
//         <div className="drawer-content1">
//           <img
//             src={photoURL || appLogo}
//             alt="UserImage"
//             className="profile-photo"
//           />

//           <div className="name-div1">
//             <p>Hi! {name}</p>
//             <span>{email}</span>
//           </div>
//         </div>

//         <div className="drawer-options1">
//           <div className="drawer-option1">
//             <PermIdentityOutlinedIcon />
//             <span>Profile Settings</span>
//           </div>

//           <div className="drawer-option1">
//             <DarkModeOutlinedIcon />
//             <span>Apperence</span>
//           </div>

//           <div className="drawer-option1">
//             <PrivacyTipOutlinedIcon />
//             <span>Privacy & Security</span>
//           </div>
//           <div className="drawer-option1">
//             <ManageAccountsOutlinedIcon />
//             <span>Manage Accounts</span>
//           </div>

//           <div className="drawer-option1">
//             <HelpOutlineOutlinedIcon />
//             <span>Help</span>
//           </div>

//           <div className="drawer-option1">
//             <PolicyOutlinedIcon />
//             <span>Privacy Policy</span>
//           </div>

//           <div className="logout-btn1" onClick={onLogout}>
//             <LogoutOutlinedIcon />
//             <button>Logout</button>
//           </div>
//         </div>
//       </div>

//       {/* Overlay when drawer is open */}
//       {isDrawerOpen && (
//         <div className="drawer-overlay" onClick={toggleDrawer}></div>
//       )}
//       {isSettingsOpen && (
//         <div className="drawer-overlay" onClick={toggleSettings}></div>
//       )}
//     </>
//   );
// }

// export default Header1;

//   CORRECT ONE

// function Header1({ email, onLogout, name, photoURL, files, handleFileSearch }) {
//   const navigate = useNavigate();

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//   function handelLogoClick() {
//     navigate("/home");
//   }

//   function toggleDrawer() {
//     console.log("Comming In Profile");
//     if (isSettingsOpen) {
//       setIsSettingsOpen(false);
//       console.log("Comming Middel profile");
//     }
//     setIsDrawerOpen((prev) => !prev);
//     console.log("Comming End profile");
//   }

//   // function toggleSettings() {
//   //   console.log("Comming In Setting");
//   //   if (isDrawerOpen) {
//   //     setIsDrawerOpen(false);
//   //     console.log("Comming middel setting");
//   //   }
//   //   setIsSettingsOpen((prev) => !prev);
//   //   console.log("Comming middle setting");
//   // }
//   function toggleSettings() {
//     console.log("Comming In Setting");

//     setIsDrawerOpen(false);
//     console.log("Comming middel setting");

//     setIsSettingsOpen((prev) => !prev);
//     console.log("Comming middle setting");
//   }

//   const usedStorage = 350;
//   const totalStorage = 500;

//   const percentageUsed = (usedStorage / totalStorage) * 100;

//   return (
//     <>
//       <header className="header1">
//         <div className="header1-logo">
//           <img
//             src={logo1}
//             alt="Company Logo"
//             onClick={handelLogoClick}
//             style={{ borderRadius: "5px" }}
//             draggable="false"
//           />
//           <span>Drive</span>
//         </div>

//         <div>
//           <SearchInput files={files} handleFileSearch={handleFileSearch} />
//         </div>

//         <div className="header1-icons">
//           <span>
//             <img
//               className="left-icon"
//               src={Bell}
//               alt="NotificationIcon"
//               draggable="false"
//             />
//             <img
//               className="left-icon"
//               src={Settings}
//               alt="SetingsIcon"
//               draggable="false"
//               onClick={toggleSettings}
//             />
//           </span>
//           <span>
//             <img
//               src={profile}
//               alt="Profile Icon"
//               onClick={toggleDrawer}
//               draggable="false"
//             />
//           </span>
//         </div>
//       </header>

//       {/* Settings Drawer  */}
//       <div className={`custom-drawer-setting ${isSettingsOpen ? "open" : ""}`}>
//         <div className="drawer-options1 drawer-options-setting">
//           <div className="drawer-option1 drawer-option-setting">
//             <GroupsOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">
//                 User management
//               </span>
//               <span className="drawer-text-secondary-setting">
//                 Add users, groups, and manage access request.
//               </span>
//             </div>
//           </div>

//           <div className="drawer-option1 drawer-option-setting">
//             <ReceiptOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">Billing</span>
//               <span className="drawer-text-secondary-setting">
//                 Update your billing details, manage your subscriptions and more.
//               </span>
//             </div>
//           </div>
//           {/* Add the storage functionality directly here */}
//           <div className="storage-container">
//             <div className="storage-text">
//               <CloudOutlined className="cloud-icon" /> Storage Overview
//             </div>
//             <div className="storage-bar">
//               <div
//                 className="storage-bar-fill"
//                 style={{
//                   width: `${percentageUsed}%`,
//                 }}
//               ></div>
//             </div>
//             <div className="storage-info">
//               {usedStorage.toFixed(2)} GB of {totalStorage} GB used
//             </div>
//             <button
//               className="storage-upgrade-btn"
//               onMouseEnter={(e) => {
//                 e.target.classList.add("hover");
//               }}
//               onMouseLeave={(e) => {
//                 e.target.classList.remove("hover");
//               }}
//             >
//               Get more storage
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* -------------------------------- */}

//       {/* Custom Drawer  */}
//       <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
//         <div className="drawer-content1">
//           <img
//             src={photoURL || appLogo}
//             alt="UserImage"
//             className="profile-photo"
//           />

//           <div className="name-div1">
//             <p>Hi! {name}</p>
//             <span>{email}</span>
//           </div>
//         </div>

//         <div className="drawer-options1">
//           <div className="drawer-option1">
//             <PermIdentityOutlinedIcon />
//             <span>Profile Settings</span>
//           </div>

//           <div className="drawer-option1">
//             <DarkModeOutlinedIcon />
//             <span>Apperence</span>
//           </div>

//           <div className="drawer-option1">
//             <PrivacyTipOutlinedIcon />
//             <span>Privacy & Security</span>
//           </div>
//           <div className="drawer-option1">
//             <ManageAccountsOutlinedIcon />
//             <span>Manage Accounts</span>
//           </div>

//           <div className="drawer-option1">
//             <HelpOutlineOutlinedIcon />
//             <span>Help</span>
//           </div>

//           <div className="drawer-option1">
//             <PolicyOutlinedIcon />
//             <span>Privacy Policy</span>
//           </div>

//           <div className="logout-btn1" onClick={onLogout}>
//             <LogoutOutlinedIcon />
//             <button>Logout</button>
//           </div>
//         </div>
//       </div>

//       {/* Overlay when drawer is open */}
//       {isDrawerOpen && (
//         <div className="drawer-overlay" onClick={toggleDrawer}></div>
//       )}
//       {isSettingsOpen && (
//         <div className="drawer-overlay" onClick={toggleSettings}></div>
//       )}
//     </>
//   );
// }

// export default Header1;

// function Header1({ email, onLogout, name, photoURL, files, handleFileSearch }) {
//   const navigate = useNavigate();

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   function handelLogoClick() {
//     navigate("/home");
//   }

//   // const openModal = () => setIsModalOpen(true);
//   const openModal = () => {
//     setIsModalOpen(true);
//     setIsSettingsOpen(false);
//   };
//   const closeModal = () => {
//     setIsModalOpen(false);
//     setIsSettingsOpen(true);
//   };

//   function toggleDrawer() {
//     console.log("Comming In Profile");
//     if (isSettingsOpen) {
//       setIsSettingsOpen(false);
//       console.log("Comming Middel profile");
//     }
//     setIsDrawerOpen((prev) => !prev);
//     console.log("Comming End profile");
//   }

//   function toggleSettings() {
//     console.log("Comming In Setting");

//     setIsDrawerOpen(false);
//     console.log("Comming middel setting");

//     setIsSettingsOpen((prev) => !prev);
//     console.log("Comming middle setting");
//   }

//   const usedStorage = 350;
//   const totalStorage = 500;

//   const percentageUsed = (usedStorage / totalStorage) * 100;

//   return (
//     <>
//       <header className="header1">
//         <div className="header1-logo">
//           <img
//             src={logo1}
//             alt="Company Logo"
//             onClick={handelLogoClick}
//             style={{ borderRadius: "5px" }}
//             draggable="false"
//           />
//           <span>Drive</span>
//         </div>

//         <div>
//           <SearchInput files={files} handleFileSearch={handleFileSearch} />
//         </div>

//         <div className="header1-icons">
//           <span>
//             <img
//               className="left-icon"
//               src={Bell}
//               alt="NotificationIcon"
//               draggable="false"
//             />
//             <img
//               className="left-icon"
//               src={Settings}
//               alt="SetingsIcon"
//               draggable="false"
//               onClick={toggleSettings}
//             />
//           </span>
//           <span>
//             <img
//               src={profile}
//               alt="Profile Icon"
//               onClick={toggleDrawer}
//               draggable="false"
//             />
//           </span>
//         </div>
//       </header>

//       {/* Settings Drawer  */}
//       <div className={`custom-drawer-setting ${isSettingsOpen ? "open" : ""}`}>
//         <div className="drawer-options1 drawer-options-setting">
//           <div
//             className="drawer-option1 drawer-option-setting"
//             onClick={openModal}
//           >
//             <GroupsOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">
//                 User management
//               </span>
//               <span className="drawer-text-secondary-setting">
//                 Add users, groups, and manage access request.
//               </span>
//             </div>
//           </div>

//           <div className="drawer-option1 drawer-option-setting">
//             <ReceiptOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">Billing</span>
//               <span className="drawer-text-secondary-setting">
//                 Update your billing details, manage your subscriptions and more.
//               </span>
//             </div>
//           </div>
//           {/* Add the storage functionality directly here */}
//           <div className="storage-container">
//             <div className="storage-text">
//               <CloudOutlined className="cloud-icon" /> Storage Overview
//             </div>
//             <div className="storage-bar">
//               <div
//                 className="storage-bar-fill"
//                 style={{
//                   width: `${percentageUsed}%`,
//                 }}
//               ></div>
//             </div>
//             <div className="storage-info">
//               {usedStorage.toFixed(2)} GB of {totalStorage} GB used
//             </div>
//             <button
//               className="storage-upgrade-btn"
//               onMouseEnter={(e) => {
//                 e.target.classList.add("hover");
//               }}
//               onMouseLeave={(e) => {
//                 e.target.classList.remove("hover");
//               }}
//             >
//               Get more storage
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* -------------------------------- */}

//       {/* Custom Drawer  */}
//       <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
//         <div className="drawer-content1">
//           <img
//             src={photoURL || appLogo}
//             alt="UserImage"
//             className="profile-photo"
//           />

//           <div className="name-div1">
//             <p>Hi! {name}</p>
//             <span>{email}</span>
//           </div>
//         </div>

//         <div className="drawer-options1">
//           <div className="drawer-option1">
//             <PermIdentityOutlinedIcon />
//             <span>Profile Settings</span>
//           </div>

//           <div className="drawer-option1">
//             <DarkModeOutlinedIcon />
//             <span>Apperence</span>
//           </div>

//           <div className="drawer-option1">
//             <PrivacyTipOutlinedIcon />
//             <span>Privacy & Security</span>
//           </div>
//           <div className="drawer-option1">
//             <ManageAccountsOutlinedIcon />
//             <span>Manage Accounts</span>
//           </div>

//           <div className="drawer-option1">
//             <HelpOutlineOutlinedIcon />
//             <span>Help</span>
//           </div>

//           <div className="drawer-option1">
//             <PolicyOutlinedIcon />
//             <span>Privacy Policy</span>
//           </div>

//           <div className="logout-btn1" onClick={onLogout}>
//             <LogoutOutlinedIcon />
//             <button>Logout</button>
//           </div>
//         </div>
//       </div>

//       {/* Overlay when drawer is open */}
//       {isDrawerOpen && (
//         <div className="drawer-overlay" onClick={toggleDrawer}></div>
//       )}
//       {isSettingsOpen && (
//         <div className="drawer-overlay" onClick={toggleSettings}></div>
//       )}
//       {/* Modal For User Management */}
//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-container" onClick={(e) => e.stopPropagation()}>
//             <div className="modal-header">
//               <h2>User Management</h2>
//               <button className="close-button" onClick={closeModal}>
//                 &times;
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default Header1;

// const myData = {
//   sap: 0,
//   dance: [
//     {
//       numberofCars: "will be the most cars of unique",
//       Capacity: 4,
//       model: 2,
//       type: "Model_Sedan_coupe",
//       wheel: "boole",
//       color: "BLACK",
//     },
//     {
//       numberofCars: "will be the most cars of unique audi",
//       Capacity: 2,
//       model: 4,
//       type: "Model_Sedan_coupe_sport",
//       wheel: "boole",
//       color: "RED",
//     },
//     {
//       numberofCars: "will be the most cars of unique audi",
//       Capacity: 2,
//       model: 4,
//       type: "Model_Sedan_coupe_sport",
//       wheel: "boole",
//       color: "RED",
//     },
//     {
//       numberofCars: "will be the most cars of unique audi",
//       Capacity: 2,
//       model: 4,
//       type: "Model_Sedan_coupe_sport",
//       wheel: "boole",
//       color: "RED",
//     },
//     {
//       numberofCars: "will be the most cars of unique audi",
//       Capacity: 2,
//       model: 4,
//       type: "Model_Sedan_coupe_sport",
//       wheel: "boole",
//       color: "RED",
//     },
//   ],
//   happines: null,
//   willrore: false,
// };

// function Header1({ email, onLogout, name, photoURL, files, handleFileSearch }) {
//   const navigate = useNavigate();

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   function handelLogoClick() {
//     navigate("/home");
//   }

//   // const openModal = () => setIsModalOpen(true);
//   const openModal = () => {
//     setIsModalOpen(true);
//     setIsSettingsOpen(false);
//   };
//   const closeModal = () => {
//     setIsModalOpen(false);
//     setIsSettingsOpen(true);
//   };

//   function toggleDrawer() {
//     console.log("Comming In Profile");
//     if (isSettingsOpen) {
//       setIsSettingsOpen(false);
//       console.log("Comming Middel profile");
//     }
//     setIsDrawerOpen((prev) => !prev);
//     console.log("Comming End profile");
//   }

//   function toggleSettings() {
//     console.log("Comming In Setting");

//     setIsDrawerOpen(false);
//     console.log("Comming middel setting");

//     setIsSettingsOpen((prev) => !prev);
//     console.log("Comming middle setting");
//   }

//   const usedStorage = 350;
//   const totalStorage = 500;

//   const percentageUsed = (usedStorage / totalStorage) * 100;

//   return (
//     <>
//       <header className="header1">
//         <div className="header1-logo">
//           <img
//             src={logo1}
//             alt="Company Logo"
//             onClick={handelLogoClick}
//             style={{ borderRadius: "5px" }}
//             draggable="false"
//           />
//           <span>Drive</span>
//         </div>

//         <div>
//           <SearchInput files={files} handleFileSearch={handleFileSearch} />
//         </div>

//         <div className="header1-icons">
//           <span>
//             <img
//               className="left-icon"
//               src={Bell}
//               alt="NotificationIcon"
//               draggable="false"
//             />
//             <img
//               className="left-icon"
//               src={Settings}
//               alt="SetingsIcon"
//               draggable="false"
//               onClick={toggleSettings}
//             />
//           </span>
//           <span>
//             <img
//               src={profile}
//               alt="Profile Icon"
//               onClick={toggleDrawer}
//               draggable="false"
//             />
//           </span>
//         </div>
//       </header>

//       {/* Settings Drawer  */}
//       <div className={`custom-drawer-setting ${isSettingsOpen ? "open" : ""}`}>
//         <div className="drawer-options1 drawer-options-setting">
//           <div
//             className="drawer-option1 drawer-option-setting"
//             onClick={openModal}
//           >
//             <GroupsOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">
//                 User management
//               </span>
//               <span className="drawer-text-secondary-setting">
//                 Add users, groups, and manage access request.
//               </span>
//             </div>
//           </div>

//           <div className="drawer-option1 drawer-option-setting">
//             <ReceiptOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">Billing</span>
//               <span className="drawer-text-secondary-setting">
//                 Update your billing details, manage your subscriptions and more.
//               </span>
//             </div>
//           </div>
//           {/* Add the storage functionality directly here */}
//           <div className="storage-container">
//             <div className="storage-text">
//               <CloudOutlined className="cloud-icon" /> Storage Overview
//             </div>
//             <div className="storage-bar">
//               <div
//                 className="storage-bar-fill"
//                 style={{
//                   width: `${percentageUsed}%`,
//                 }}
//               ></div>
//             </div>
//             <div className="storage-info">
//               {usedStorage.toFixed(2)} GB of {totalStorage} GB used
//             </div>
//             <button
//               className="storage-upgrade-btn"
//               onMouseEnter={(e) => {
//                 e.target.classList.add("hover");
//               }}
//               onMouseLeave={(e) => {
//                 e.target.classList.remove("hover");
//               }}
//             >
//               Get more storage
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* -------------------------------- */}

//       {/* Custom Drawer  */}
//       <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
//         <div className="drawer-content1">
//           <img
//             src={photoURL || appLogo}
//             alt="UserImage"
//             className="profile-photo"
//           />

//           <div className="name-div1">
//             <p>Hi! {name}</p>
//             <span>{email}</span>
//           </div>
//         </div>

//         <div className="drawer-options1">
//           <div className="drawer-option1">
//             <PermIdentityOutlinedIcon />
//             <span>Profile Settings</span>
//           </div>

//           <div className="drawer-option1">
//             <DarkModeOutlinedIcon />
//             <span>Apperence</span>
//           </div>

//           <div className="drawer-option1">
//             <PrivacyTipOutlinedIcon />
//             <span>Privacy & Security</span>
//           </div>
//           <div className="drawer-option1">
//             <ManageAccountsOutlinedIcon />
//             <span>Manage Accounts</span>
//           </div>

//           <div className="drawer-option1">
//             <HelpOutlineOutlinedIcon />
//             <span>Help</span>
//           </div>

//           <div className="drawer-option1">
//             <PolicyOutlinedIcon />
//             <span>Privacy Policy</span>
//           </div>

//           <div className="logout-btn1" onClick={onLogout}>
//             <LogoutOutlinedIcon />
//             <button>Logout</button>
//           </div>
//         </div>
//       </div>

//       {/* Overlay when drawer is open */}
//       {isDrawerOpen && (
//         <div className="drawer-overlay" onClick={toggleDrawer}></div>
//       )}
//       {isSettingsOpen && (
//         <div className="drawer-overlay" onClick={toggleSettings}></div>
//       )}
//       {/* Modal For User Management */}
//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-container" onClick={(e) => e.stopPropagation()}>
//             <div className="modal-header">
//               <h2>Cars World</h2>
//               <button className="close-button" onClick={closeModal}>
//                 &times;
//               </button>
//             </div>

//             {/* Table Section */}
//             <div className="table-wrapper">
//               <div className="table-container">
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th className="expand">Number of Cars</th>
//                       <th>Capacity</th>
//                       <th>Model</th>
//                       <th>Type</th>
//                       <th>Wheel</th>
//                       <th>Color</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {myData.dance.map((car, index) => (
//                       <tr key={index}>
//                         <td>{car.numberofCars}</td>
//                         <td>{car.Capacity}</td>
//                         <td>{car.model}</td>
//                         <td>{car.type}</td>
//                         <td>
//                           <span className="label label-live">{car.wheel}</span>
//                         </td>
//                         <td>{car.color}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default Header1;

const myData = {
  sap: 0,
  dance: [
    {
      numberofCars: "will be the most cars of unique",
      Capacity: 4,
      model: 2,
      type: "Model_Sedan_coupe",
      wheel: "boole",
      color: "BLACK",
    },
    {
      numberofCars: "will be the most cars of unique audi",
      Capacity: 2,
      model: 4,
      type: "Model_Sedan_coupe_sport",
      wheel: "boole",
      color: "RED",
    },
    {
      numberofCars: "will be the most cars of unique audi",
      Capacity: 2,
      model: 4,
      type: "Model_Sedan_coupe_sport",
      wheel: "boole",
      color: "RED",
    },
    {
      numberofCars: "will be the most cars of unique audi",
      Capacity: 2,
      model: 4,
      type: "Model_Sedan_coupe_sport",
      wheel: "boole",
      color: "RED",
    },
    {
      numberofCars: "will be the most cars of unique audi",
      Capacity: 2,
      model: 4,
      type: "Model_Sedan_coupe_sport",
      wheel: "boole",
      color: "RED",
    },
  ],
  happines: null,
  willrore: false,
};

// function Header1({ email, onLogout, name, photoURL, files, handleFileSearch }) {
//   const navigate = useNavigate();

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const [cars, setCars] = useState(myData.dance);

//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

//   const [deleteIndex, setDeleteIndex] = useState(null);

//   const [editingIndex, setEditingIndex] = useState(null);
//   const [newCapacity, setNewCapacity] = useState("");
//   const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
//   const [carToDelete, setCarToDelete] = useState(null);

//   const inputRef = useRef(null); // Reference for the input field

//   function handelLogoClick() {
//     navigate("/home");
//   }

//   // const openModal = () => setIsModalOpen(true);
//   const openModal = () => {
//     setIsModalOpen(true);
//     setIsSettingsOpen(false);
//   };
//   const closeModal = () => {
//     setIsModalOpen(false);
//     setIsSettingsOpen(true);
//   };

//   function toggleDrawer() {
//     console.log("Comming In Profile");
//     if (isSettingsOpen) {
//       setIsSettingsOpen(false);
//       console.log("Comming Middel profile");
//     }
//     setIsDrawerOpen((prev) => !prev);
//     console.log("Comming End profile");
//   }

//   function toggleSettings() {
//     console.log("Comming In Setting");

//     setIsDrawerOpen(false);
//     console.log("Comming middel setting");

//     setIsSettingsOpen((prev) => !prev);
//     console.log("Comming middle setting");
//   }

//   const usedStorage = 350;
//   const totalStorage = 500;

//   const percentageUsed = (usedStorage / totalStorage) * 100;

//   const openDeleteConfirm = (index) => {
//     setCarToDelete(index); // Store the car index to delete
//     setIsDeleteConfirmOpen(true); // Open the confirmation modal
//   };

//   const closeDeleteConfirm = () => {
//     setIsDeleteConfirmOpen(false); // Close the confirmation modal
//     setCarToDelete(null); // Clear the car index
//   };

//   const handleDelete = () => {
//     const updatedCars = cars.filter((_, i) => i !== carToDelete); // Remove car
//     setCars(updatedCars);
//     closeDeleteConfirm(); // Close the delete confirmation modal
//   };

//   const handleCapacityChange = (e) => {
//     setNewCapacity(e.target.value); // Update the new capacity value
//   };

//   const handleKeyDown = (e, index) => {
//     // If Enter key is pressed, save the capacity
//     if (e.key === "Enter") {
//       saveCapacity(index);
//     }
//   };

//   const saveCapacity = (index) => {
//     const updatedCars = [...cars];
//     updatedCars[index].Capacity = newCapacity; // Update the capacity for the selected car
//     setCars(updatedCars); // Set the new cars state with updated capacity
//     setEditingIndex(null); // Close the input field after saving
//   };

//   const handleEdit = (index) => {
//     // Check if the car exists at the given index before proceeding
//     if (cars[index]) {
//       // Save the current capacity before changing the edit, if applicable
//       if (editingIndex !== null && editingIndex !== index) {
//         saveCapacity(editingIndex); // Save the capacity of the previous car
//       }
//       setEditingIndex(index); // Set the index of the car being edited
//       setNewCapacity(cars[index].Capacity); // Set the current capacity to the newCapacity state
//     }
//   };

//   const handleClickOutside = (e) => {
//     // Check if the clicked element is outside the input field
//     if (
//       inputRef.current &&
//       !inputRef.current.contains(e.target) &&
//       editingIndex !== null
//     ) {
//       // Save capacity directly when clicking outside the input field
//       saveCapacity(editingIndex);
//     }
//   };

//   // Effect hook to listen for clicks outside the input field
//   useEffect(() => {
//     document.addEventListener("click", handleClickOutside); // Add event listener for clicks
//     return () => {
//       document.removeEventListener("click", handleClickOutside); // Clean up the event listener on unmount
//     };
//   }, [editingIndex]);

//   // Focus the input when editing begins
//   useEffect(() => {
//     if (editingIndex !== null) {
//       inputRef.current && inputRef.current.focus(); // Focus on the input when editing starts
//     }
//   }, [editingIndex]);

//   return (
//     <>
//       <header className="header1">
//         <div className="header1-logo">
//           <img
//             src={logo1}
//             alt="Company Logo"
//             onClick={handelLogoClick}
//             style={{ borderRadius: "5px" }}
//             draggable="false"
//           />
//           <span>Drive</span>
//         </div>

//         <div>
//           <SearchInput files={files} handleFileSearch={handleFileSearch} />
//         </div>

//         <div className="header1-icons">
//           <span>
//             <img
//               className="left-icon"
//               src={Bell}
//               alt="NotificationIcon"
//               draggable="false"
//             />
//             <img
//               className="left-icon"
//               src={Settings}
//               alt="SetingsIcon"
//               draggable="false"
//               onClick={toggleSettings}
//             />
//           </span>
//           <span>
//             <img
//               src={profile}
//               alt="Profile Icon"
//               onClick={toggleDrawer}
//               draggable="false"
//             />
//           </span>
//         </div>
//       </header>

//       {/* Settings Drawer  */}
//       <div className={`custom-drawer-setting ${isSettingsOpen ? "open" : ""}`}>
//         <div className="drawer-options1 drawer-options-setting">
//           <div
//             className="drawer-option1 drawer-option-setting"
//             onClick={openModal}
//           >
//             <GroupsOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">
//                 User management
//               </span>
//               <span className="drawer-text-secondary-setting">
//                 Add users, groups, and manage access request.
//               </span>
//             </div>
//           </div>

//           <div className="drawer-option1 drawer-option-setting">
//             <ReceiptOutlinedIcon className="drawer-icon-setting" />
//             <div className="drawer-texts-setting">
//               <span className="drawer-text-primary-setting">Billing</span>
//               <span className="drawer-text-secondary-setting">
//                 Update your billing details, manage your subscriptions and more.
//               </span>
//             </div>
//           </div>
//           {/* Add the storage functionality directly here */}
//           <div className="storage-container">
//             <div className="storage-text">
//               <CloudOutlined className="cloud-icon" /> Storage Overview
//             </div>
//             <div className="storage-bar">
//               <div
//                 className="storage-bar-fill"
//                 style={{
//                   width: `${percentageUsed}%`,
//                 }}
//               ></div>
//             </div>
//             <div className="storage-info">
//               {usedStorage.toFixed(2)} GB of {totalStorage} GB used
//             </div>
//             <button
//               className="storage-upgrade-btn"
//               onMouseEnter={(e) => {
//                 e.target.classList.add("hover");
//               }}
//               onMouseLeave={(e) => {
//                 e.target.classList.remove("hover");
//               }}
//             >
//               Get more storage
//             </button>
//           </div>
//         </div>
//       </div>

//       {/* -------------------------------- */}

//       {/* Custom Drawer  */}
//       <div className={`custom-drawer ${isDrawerOpen ? "open" : ""}`}>
//         <div className="drawer-content1">
//           <img
//             src={photoURL || appLogo}
//             alt="UserImage"
//             className="profile-photo"
//           />

//           <div className="name-div1">
//             <p>Hi! {name}</p>
//             <span>{email}</span>
//           </div>
//         </div>

//         <div className="drawer-options1">
//           <div className="drawer-option1">
//             <PermIdentityOutlinedIcon />
//             <span>Profile Settings</span>
//           </div>

//           <div className="drawer-option1">
//             <DarkModeOutlinedIcon />
//             <span>Apperence</span>
//           </div>

//           <div className="drawer-option1">
//             <PrivacyTipOutlinedIcon />
//             <span>Privacy & Security</span>
//           </div>
//           <div className="drawer-option1">
//             <ManageAccountsOutlinedIcon />
//             <span>Manage Accounts</span>
//           </div>

//           <div className="drawer-option1">
//             <HelpOutlineOutlinedIcon />
//             <span>Help</span>
//           </div>

//           <div className="drawer-option1">
//             <PolicyOutlinedIcon />
//             <span>Privacy Policy</span>
//           </div>

//           <div className="logout-btn1" onClick={onLogout}>
//             <LogoutOutlinedIcon />
//             <button>Logout</button>
//           </div>
//         </div>
//       </div>

//       {/* Overlay when drawer is open */}
//       {isDrawerOpen && (
//         <div className="drawer-overlay" onClick={toggleDrawer}></div>
//       )}
//       {isSettingsOpen && (
//         <div className="drawer-overlay" onClick={toggleSettings}></div>
//       )}
//       {/* Modal For User Management */}
//       {isModalOpen && (
//         <div className="modal-overlay" onClick={closeModal}>
//           <div className="modal-container" onClick={(e) => e.stopPropagation()}>
//             <div className="modal-header">
//               <h2>Cars World</h2>
//               <button className="close-button" onClick={closeModal}>
//                 &times;
//               </button>
//             </div>

//             <button className="add-user-btn">
//               <span>
//                 Add Car
//                 <PlusOne />
//               </span>
//             </button>

//             {/* Table Section */}
//             <div className="table-wrapper">
//               <div className="table-container">
//                 <table className="table">
//                   <thead>
//                     <tr>
//                       <th>Remove</th>
//                       <th className="expand">Number of Cars</th>
//                       <th>Capacity</th>
//                       <th>Model</th>
//                       <th>Type</th>
//                       <th>Wheel</th>
//                       <th>Color</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {myData.dance.map((car, index) => (
//                       <tr key={index}>
//                         <td>
//                           <DeleteOutlineOutlinedIcon
//                             style={{ width: "15px", background: "white" }}
//                             onClick={() => openDeleteConfirm(index)}
//                           />
//                         </td>
//                         <td>{car.numberofCars}</td>
//                         <td>
//                           {/* <span
//                             style={{
//                               display: "flex",
//                               alignItems: "center",
//                               gap: "5px",
//                               background: "white",
//                             }}
//                           >
//                             {car.Capacity}
//                             <Edit
//                               style={{ width: "15px", background: "white" }}
//                             />
//                           </span> */}

//                           <span
//                             style={{
//                               display: "flex",
//                               alignItems: "center",
//                               gap: "5px",
//                               background: "white",
//                             }}
//                           >
//                             {editingIndex === index ? (
//                               <input
//                                 ref={inputRef}
//                                 type="text"
//                                 value={newCapacity}
//                                 onChange={handleCapacityChange}
//                                 onKeyDown={(e) => handleKeyDown(e, index)} // Save on Enter key press
//                                 style={{ width: "50px", fontSize: "14px" }}
//                               />
//                             ) : (
//                               <>
//                                 {car.Capacity}
//                                 <Edit
//                                   style={{
//                                     width: "15px",
//                                     background: "white",
//                                     cursor: "pointer",
//                                   }}
//                                   onClick={() => handleEdit(index)} // Open input field for editing
//                                 />
//                               </>
//                             )}
//                           </span>
//                         </td>
//                         <td>{car.model}</td>
//                         <td>{car.type}</td>
//                         <td>
//                           <span className="label label-live">{car.wheel}</span>
//                         </td>
//                         <td>{car.color}</td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {isDeleteConfirmOpen && (
//         <div className="delete-confirm-overlay" onClick={closeDeleteConfirm}>
//           <div
//             className="delete-confirm-container"
//             onClick={(e) => e.stopPropagation()}
//           >
//             <h3>Are you sure you want to delete this car?</h3>
//             <button className="btn-yes" onClick={handleDelete}>
//               Proceed
//             </button>
//             <button className="btn-no" onClick={closeDeleteConfirm}>
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// }

// export default Header1;
