import pdfIcon from "../assets/pdf.png";
import htmlIcon from "../assets/html.png";
import jsonIcon from "../assets/json.png";
import mpgIcon from "../assets/mpg-file-format.png";
import mp3Icon from "../assets/music.png";
import txtIcon from "../assets/txt.png";
import videoIcon from "../assets/video.png";
import xmlIcon from "../assets/xml.png";
import zipIcon from "../assets/zip.png";
import xlsIcon from "../assets/xls.png";
import xlsxIcon from "../assets/xlsx.png";
import docxIcon from "../assets/docx.png";
import docIcon from "../assets/word (1).png";
import commonIcon from "../assets/camera.png";
import defaultFile from "../assets/document.png";
import tiffIcon from "../assets/tif.png";
import gifIcon from "../assets/gif.png";
import bmpIcon from "../assets/bmp-file.png";
import k25Icon from "../assets/k25.png";
import kdcIcon from "../assets/kdc.png";
import cr2Icon from "../assets/cr2.png";
import nefIcon from "../assets/nef.png";
import crwIcon from "../assets/crw.png";
import erfIcon from "../assets/erf.png";
import mefIcon from "../assets/mos.png";
import mosIcon from "../assets/mos.png";
import nrwIcon from "../assets/nrw.png";
import orfIcon from "../assets/orf.png";
import pefIcon from "../assets/pef.png";
import rw2Icon from "../assets/rw2.png";
import arwIcon from "../assets/arw.png";
import sr2Icon from "../assets/sr2.png";
import heifIcon from "../assets/heif.png";
import csvIcon from "../assets/csv.png";
import pptIcon from "../assets/ppt.png";
import pptxIcon from "../assets/pptx-file.png";

const fileIcons = {
  pdf: pdfIcon,
  png: commonIcon,
  html: htmlIcon,
  jpeg: commonIcon,
  jpg: commonIcon,
  json: jsonIcon,
  mpg: mpgIcon,
  mp3: mp3Icon,
  txt: txtIcon,
  mp4: videoIcon,
  xml: xmlIcon,
  zip: zipIcon,
  doc: docIcon,
  webp: commonIcon,
  xls: xlsIcon,
  xlsx: xlsxIcon,
  docx: docxIcon,
  tiff: tiffIcon,
  gif: gifIcon,
  bmp: bmpIcon,
  k25: k25Icon,
  kdc: kdcIcon,
  cr2: cr2Icon,
  nef: nefIcon,
  crw: crwIcon,
  erf: erfIcon,
  mef: mefIcon,
  mos: mosIcon,
  nrw: nrwIcon,
  orf: orfIcon,
  pef: pefIcon,
  rw2: rw2Icon,
  arw: arwIcon,
  sr2: sr2Icon,
  heif: heifIcon,
  csv: csvIcon,
  ppt: pptIcon,
  pptx: pptxIcon,

  default: defaultFile,
};

export default fileIcons;
