import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { auth } from "./config/firebase";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import HomeScreen from "./screens/HomeScreen";
import Attorney from "./screens/Attorney";
import EmailScreen from "./screens/EmailScreen";
import FaxScreen from "./screens/FaxScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RingLoader } from "react-spinners";
import LandingScreen from "./components/LandingPage/LandingScreen";
import CheckoutPage from "./components/LandingPage/CheckoutScreen";

export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // User is authenticated
      } else {
        setUser(null); // No user, so not authenticated
      }
      setLoading(false); // Finished loading auth state
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<LandingScreen setAccessToken={setAccessToken} />}
        />
        {/* <Route
          path="/"
          element={user ? <Navigate to="/home" /> : <LoginScreen />}
        /> */}
        {/* <Route path={`${LOGIN_URL}/login`} element={<LoginScreen />} /> */}
        <Route
          path="/login"
          element={<LoginScreen setAccessToken={setAccessToken} />}
        />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route
          path="/home"
          element={
            user ? (
              <HomeScreen
                accessToken={accessToken}
                setAccessToken={setAccessToken}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {/* <Route
          path="/upload"
          element={user ? <UploadScreen /> : <Navigate to="/" />}
        /> */}
        <Route
          path="/attorney"
          element={user ? <Attorney /> : <Navigate to="/" />}
        />
        <Route
          path="/email"
          element={user ? <EmailScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/fax"
          element={user ? <FaxScreen /> : <Navigate to="/" />}
        />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
// } from "react-router-dom";
// import { auth } from "./config/firebase";
// import LoginScreen from "./screens/LoginScreen";
// import RegisterScreen from "./screens/RegisterScreen";
// import HomeScreen from "./screens/HomeScreen";
// import UploadScreen from "./screens/UploadScreen";
// import Attorney from "./screens/Attorney";
// import EmailScreen from "./screens/EmailScreen";
// import FaxScreen from "./screens/FaxScreen";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { RingLoader } from "react-spinners";

// function App() {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setUser(user); // User is authenticated
//       } else {
//         setUser(null); // No user, so not authenticated
//       }
//       setLoading(false); // Finished loading auth state
//     });

//     return () => unsubscribe(); // Clean up subscription on unmount
//   }, []);

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         <RingLoader color="#3498db" />
//       </div>
//     );
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={user ? <Navigate to="/home" /> : <LoginScreen />}
//         />
//         <Route path="/login" element={<LoginScreen />} />
//         <Route path="/register" element={<RegisterScreen />} />
//         <Route
//           path="/home"
//           element={user ? <HomeScreen /> : <Navigate to="/" />}
//         />
//         <Route
//           path="/upload"
//           element={user ? <UploadScreen /> : <Navigate to="/" />}
//         />
//         <Route
//           path="/attorney"
//           element={user ? <Attorney /> : <Navigate to="/" />}
//         />
//         <Route
//           path="/email"
//           element={user ? <EmailScreen /> : <Navigate to="/" />}
//         />
//         <Route
//           path="/fax"
//           element={user ? <FaxScreen /> : <Navigate to="/" />}
//         />
//       </Routes>
//       <ToastContainer />
//     </Router>
//   );
// }

// export default App;
