import axios from "axios";
export const FEEDBACK_API_HOST_URL = process.env.REACT_APP_FEEDBACK_API_URL;

export default async function FeedbackService(accessToken, feedBack, uploadedFiles) {
    let returnResponse = {
        data: [],
        result: false,
        status: ""
    };

    const formdata = new FormData();
    for (let i = 0; i < uploadedFiles.length; i++) {
        formdata.append("files", uploadedFiles[i].file, uploadedFiles[i].file.name);
    }
    formdata.append("feedback", feedBack);

    const headers = {
        "Cache-Control": "no-cache",
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${accessToken}`
    };

    try {
        const response = await axios.post(
            `${FEEDBACK_API_HOST_URL}`,
            formdata,
            {
                headers: headers,
            }
        );

        if (response.status === 200) {
            returnResponse.data = response.data;
            returnResponse.status = response.status;
            returnResponse.result = response.data.success;
        } else {
            console.error('Request failed with status1234:', response.status);
            returnResponse.status = response.status;
        }
    } catch (error) {
        console.error('Request error1234:', error);
        returnResponse.status = error.response ? error.response.status : error.message;
    }
    return returnResponse;
}