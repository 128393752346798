import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: [],
};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFilesStore(state, action) {
      state.files = [...action.payload];
    },
    clearFiles(state) {
      state.files = [];
    },
    removeFile(state, action) {
      const fileToRemove = action.payload.toLowerCase().trim();
      state.files = state.files.filter(file => file.name.toLowerCase().trim() !== fileToRemove);
    },
    addFile(state, action) {
      //console.log("store", action.payload);
      const fileToAdd = action.payload.name?.toLowerCase().trim();
      if (!state.files.some(file => file.name.toLowerCase().trim() === fileToAdd)) {
        state.files.push(fileToAdd);
      }
    },
  },
});

export const { setFilesStore, clearFiles, removeFile, addFile } = filesSlice.actions;
export default filesSlice.reducer;