const firebaseConfigUat = {
  apiKey: "AIzaSyB32eniEXAErgbR4zBFi3U3yz9lB8gwbqs",
  authDomain: "ezbyte-admin-panel-uat.firebaseapp.com",
  projectId: "ezbyte-admin-panel-uat",
  storageBucket: "ezbyte-admin-panel-uat.appspot.com",
  messagingSenderId: "722814875587",
  appId: "1:722814875587:web:7d57f2162f1e78c9efef76",
  measurementId: "G-GZB2M7T7SN",
};

export default firebaseConfigUat;
