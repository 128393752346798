import axios from "axios";
export const FAVORITES_API_HOST_URL =  process.env.REACT_APP_FAVORITES_API_URL
// import React, { useState, useEffect } from "react";

export default async function Favorites(accessToken) {
    let returnResponse = {
        data: [],
        result: false,
        status:""
    };
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    try {
        const response = await axios.get(
            `${FAVORITES_API_HOST_URL}`,
            { headers: headers }
        );

        if (response.status === 200) {
            returnResponse.data = response.data;
            returnResponse.status = response.status;
            returnResponse.result = true;
        } else {
            console.error('Request failed with status:', response.status);
            returnResponse.status = response.status;
        }
    } catch (error) {
        console.error('Request error:', error);
        returnResponse.status = error;
    }
    return returnResponse;
}