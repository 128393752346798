import { Adjust } from "@mui/icons-material";
import axios from "axios";

export const DROP_URL = "https://devapi.ez-byte.com:5001/multi/details";

export const UPLOAD_API_URL = process.env.REACT_APP_UPLOAD_API_URL;

export async function FilesandFolderDropService(
  filesData,
  accessToken,
  userDetail
) {
  console.log(filesData);
  // // Adjust paths if `file` is null
  // const adjustedFilesData = filesData.map((fileWrapper) => {
  //   const { file, path } = fileWrapper;

  //   // If file is null, remove the last part of the path
  //   if (file === null && path) {
  //     const trimmedPath = path.endsWith("/")
  //       ? path.slice(0, -1).split("/").slice(0, -1).join("/") + "/"
  //       : path.split("/").slice(0, -1).join("/") + "/";
  //     return { ...fileWrapper, path: trimmedPath };
  //   }

  //   return fileWrapper;
  // });

  // console.log(adjustedFilesData);

  // const adjustedFilesData = filesData.map((fileWrapper) => {
  //   const { file, path } = fileWrapper;

  //   let newPath = path;

  //   if (file === null && path) {
  //     const pathSegments = path.endsWith("/")
  //       ? path.slice(0, -1).split("/")
  //       : path.split("/");

  //     if (pathSegments.length === 1) {
  //       // If the path contains only one segment, make it an empty string
  //       newPath = "";
  //     } else {
  //       // Remove the last segment of the path
  //       pathSegments.pop();
  //       newPath = pathSegments.join("/") + "/";
  //     }
  //   }

  //   return { ...fileWrapper, path: newPath };
  // });

  const adjustedFilesData = filesData.map((fileWrapper) => {
    const { file, name } = fileWrapper;

    let newName = name;

    if (file === null) {
      newName = null;
    }

    return { ...fileWrapper, name: newName };
  });

  const uploadRequest = filesData.map((fileWrapper) => {
    const { file, name, size, path, category } = fileWrapper;
    return {
      file,
      // name: name || "defaultName",
      name: name,
      size,
      path: path || "", // Keep empty string for root
      category: category || "3300",
      userid: userDetail.email || "defaultUser",
    };
  });

  const responses = [];
  let breakdownFiles = [];

  for (let i = 0; i < uploadRequest.length; i++) {
    breakdownFiles.push(uploadRequest[i]);

    if ((i + 1) % 4 === 0 || i === uploadRequest.length - 1) {
      console.log("=============================");
      console.log("Submitting group for upload:", breakdownFiles);

      const formData = new FormData();
      breakdownFiles.forEach((fileWrapper) => {
        formData.append("files", fileWrapper.file);
        formData.append("category", fileWrapper.category);
        formData.append("name", fileWrapper.name);
        formData.append("userid", fileWrapper.userid);

        // Conditional path handling
        if (fileWrapper.path === "") {
          formData.append("uploadPath", "");
        } else {
          formData.append("uploadPath", fileWrapper.path);
        }
      });
      formData.append("uploadPath", ""); // Duplicate for empty path case
      try {
        const headers = {
          "Cache-Control": "no-cache",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        };

        const response = await axios.post(
          `${UPLOAD_API_URL}/multi/details`,
          formData,
          { headers }
        );

        console.log("Upload response data:", response.data);

        responses.push(response.data);
      } catch (error) {
        console.error("Error uploading files:", error);
        throw error;
      }

      breakdownFiles = [];

      console.log("=============================");
    }
  }

  return responses;
}

// export async function FilesandFolderDropService(
//   filesData,
//   accessToken,
//   userDetail
// ) {
//   const uploadRequest = filesData.map((fileWrapper) => {
//     const { file, name, size, path, category } = fileWrapper;
//     return {
//       file,
//       name: name || "defaultName",
//       size,
//       path: path || "", // Keep empty string for root
//       category: category || "3300",
//       userid: userDetail.email || "defaultUser",
//     };
//   });

//   const responses = [];
//   let breakdownFiles = [];

//   for (let i = 0; i < uploadRequest.length; i++) {
//     breakdownFiles.push(uploadRequest[i]);

//     if ((i + 1) % 4 === 0 || i === uploadRequest.length - 1) {
//       console.log("=============================");
//       console.log("Submitting group for upload:", breakdownFiles);

//       const formData = new FormData();
//       breakdownFiles.forEach((fileWrapper) => {
//         formData.append("files", fileWrapper.file);
//         formData.append("category", fileWrapper.category);
//         formData.append("name", fileWrapper.name);
//         formData.append("userid", fileWrapper.userid);

//         // Conditional path handling
//         if (fileWrapper.path === "") {
//           formData.append("path", "");
//           formData.append("path", ""); // Duplicate for empty path case
//         } else {
//           formData.append("path", fileWrapper.path);
//         }
//       });

//       try {
//         const headers = {
//           "Cache-Control": "no-cache",
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${accessToken}`,
//         };

//         const response = await axios.post(
//           `${UPLOAD_API_URL}/multi/details`,
//           formData,
//           { headers }
//         );

//         console.log("Upload response data:", response.data);

//         responses.push(response.data);
//       } catch (error) {
//         console.error("Error uploading files:", error);
//         throw error;
//       }

//       breakdownFiles = [];

//       console.log("=============================");
//     }
//   }

//   return responses;
// }
