import Axios from "axios";

export const DOWNLOAD_API_URL = process.env.REACT_APP_DOWNLOAD_API_URL;

export const EMAIL_API_HOST_URL = process.env.REACT_APP_EMAIL_API_URL;

// export const DOWNLOAD_API_URL = "https://devapi.ez-byte.com:5005/zip";

export default async function DownloadService(downloadData, accessToken) {
  console.log(`-------------------------DOWNLOAD DATA IN SERVICE FILE`);

  console.log(EMAIL_API_HOST_URL);
  console.log(process.env.REACT_APP_DOWNLOAD_API_URL);
  console.log(process.env.REACT_APP_AAA);

  console.log(`-------------------------DOWNLOAD DATA IN SERVICE FILE`);

  let files = downloadData.map((file) =>
    file.path.startsWith("/") ? file.path.slice(1) : file.path
  );

  console.log(`-------------------------FILES`);

  console.log(files);

  console.log(`-------------------------FILES`);

  const downloadPayload = JSON.stringify({
    downloadFileList: files,
    // downloadFileList: downloadData,
  });

  console.log(`-------------------------DOWNLOAD PAYLOAD IN SERVICE FILE`);

  console.log(downloadPayload);

  console.log(`-------------------------DOWNLOAD PAYLOAD IN SERVICE FILE`);

  const config = {
    method: "post",
    url: `${DOWNLOAD_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: downloadPayload,
    responseType: "blob",
  };

  try {
    const response = await Axios(config);
    if (response.status === 200) {
      return response.data; // Handle the response (e.g., returning file data)
    } else {
      return false;
    }
  } catch (error) {
    console.error("Download error:", error);
    return false;
  }
}
