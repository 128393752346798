import { useLocation } from "react-router-dom";

const CheckoutPage = () => {
  const location = useLocation();
  const { selectedPlan } = location.state || {}; // Retrieve the state passed during navigation

  return (
    <div>
      <header>{/* Header content */}</header>

      <div className="checkout-container">
        <h2>Checkout</h2>
        {selectedPlan ? (
          <div className="checkout-summary">
            <p>Plan: {selectedPlan.planName}</p>
            <p>Pricing: {selectedPlan.pricingType}</p>
            <p>Price: {selectedPlan.price}</p>
          </div>
        ) : (
          <p>No plan selected.</p>
        )}
      </div>

      <footer>{/* Footer content */}</footer>
    </div>
  );
};

export default CheckoutPage;
