import { useEffect, useState, useRef } from "react";
import FeedbackService from "../services/feedbackService";
import "../newComponent/Feedback.css";

export default function Feedback({ accessToken, uploadedFiles, triggerFeedbackSubmit, handleFeedbackSubmitComplete }) {
  const [txtFeedback, setTxtFeedback] = useState("");
  const feedbackRef = useRef(null);
  let _feedbackResult = {
    data: [],
    result: false,
    status: "",
    errorType: "Validation" | "Server" | "Unknown"
  };

  useEffect(() => {
    if (triggerFeedbackSubmit) {
      console.log("Feedback Result:", "trigerred");
      handleFeedbackSubmit();
    }
  }, [triggerFeedbackSubmit]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent the default behavior for up and down arrow keys
      if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    // Add event listener to document when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Focus the textarea
    const textarea = feedbackRef.current;
    if (textarea) {
      textarea.focus();
    }

    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const validateFeedbackBody = () => {
    if (txtFeedback.trim().length <10 || txtFeedback.trim().length > 500) {
      return false;
    } else {
      return true;
    }
  };

  const handleFeedbackSubmit = async (e) => {
    if (validateFeedbackBody() === false) {
      _feedbackResult.errorType = "Validation";
      _feedbackResult.status = "Please provide feedback.\nFeedback should be between 10 and 500 characters.";
      handleFeedbackSubmitComplete(_feedbackResult);
      return false;
    }

    try {
      _feedbackResult = await FeedbackService(accessToken, txtFeedback, uploadedFiles);
      handleFeedbackSubmitComplete(_feedbackResult);
    } catch (err) {
      console.log("Error sending Feedback:", err);
      _feedbackResult.status = err;
      _feedbackResult.result = false;
      _feedbackResult.data = [];
      handleFeedbackSubmitComplete(_feedbackResult);
    }
  };

  return (
    <div className="feedback-container">
    <textarea
      placeholder="Feedback"
      ref={feedbackRef}
      className="feedback-textarea"
      value={txtFeedback}
      maxLength={500}
      rows={2}
      onChange={(e) => setTxtFeedback(e.target.value)}
      onBlur={validateFeedbackBody}
      required
    />
    </div>
  );
}