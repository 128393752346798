import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  emailsList: [],
  emailsValid: false,
};

const emailFilesSlice = createSlice({
  name: 'emailFiles',
  initialState,
  reducers: {
    addEmailToList(state, action) {
      const newEmail = action.payload.toLowerCase().trim();
      if (!state.emailsList.some(email => email === newEmail)) {
        state.emailsList.push(newEmail);
      }
      if(state.emailsList.length === 0){
        state.emailsValid = false;
      } else {
        state.emailsValid = true;
      }
    },
    clearEmailsList(state) {
      state.emailsList = [];
      state.emailsValid = false;
    },
    removeEmailFromList(state, action) {
      const emailToRemove = action.payload.toLowerCase().trim();
      state.emailsList = state.emailsList.filter(email => email !== emailToRemove);
      if(state.emailsList.length === 0){
        state.emailsValid = false;
      } else {
        state.emailsValid = true;
      }
    },
    setEmailValidationError(state, action) {
      state.emailsValid = false;
    },
    setClearEmailValidation(state, action) {
      state.emailsValid = true;
    },
  },
});

export const { addEmailToList, clearEmailsList, removeEmailFromList, setEmailValidationError, setClearEmailValidation } = emailFilesSlice.actions;
export default emailFilesSlice.reducer;