import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "../components/SearchInput.css";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from 'react-redux';
import { setSearchTerm, setSearchClose, setSearchRefresh } from '../store/reducers/searchReducer';
import { useRef } from "react";

export default function SearchInput({ files, handleFileSearch }) {
    const searchTerm = useSelector((state) => state.search.searchTerm);
    const searchClose = useSelector((state) => state.search.searchClose);
    const searchDoRefresh = useSelector((state) => state.search.searchRefresh);
    const dispatch = useDispatch();
    const [suggestions, setSuggestions] = useState([]);
    const [matchingSuggestions, setMatchingSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const inputRef = useRef(null);
  
    useEffect(() => {
      if (searchDoRefresh) {
        dispatch(setSearchRefresh(false));
        fileSearch();
      }
      console.log("searchDoRefresh", searchDoRefresh);
    }, [searchDoRefresh]);

    useEffect(() => {
      if (searchTerm.length === 0) {
        handleSearchClose();
      } else {
        dispatch(setSearchClose(true));
      }
    }, [searchTerm]);
  
    const handleSearchInputKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < matchingSuggestions.length) {
          handleSuggestionClick(matchingSuggestions[highlightedIndex]);
        } else {
          fileSearch();
        }
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        e.stopPropagation();
        setHighlightedIndex((prevIndex) => (prevIndex - 1 + matchingSuggestions.length) % matchingSuggestions.length);
      } else if (e.key === "ArrowDown") {
        e.preventDefault();
        e.stopPropagation();
        setHighlightedIndex((prevIndex) => (prevIndex + 1) % matchingSuggestions.length);
      }
    };
  
    const handleSearchClose = (focusInput = false) => {
      dispatch(setSearchTerm(""));
      dispatch(setSearchClose(false));
      setMatchingSuggestions([]);
      setHighlightedIndex(-1);
      if (focusInput && inputRef.current) {
        inputRef.current.focus(); // Focus the input element when focusInput is true
      }
    };

    const handleClearSearch = () => {
      handleSearchClose(true); // Call handleSearchClose with true to focus the input
    };
  
  
    const handleSearchIcon = () => {
      fileSearch();
    };
  
    const fileSearch = (_searchTerm) => {
      const trimmedSearchTerm = _searchTerm !== undefined ? _searchTerm.trim() : searchTerm.trim();
      if (trimmedSearchTerm.length > 0) {
        const filteredItems = files.filter((file) => {
          const filePath = file.name.toLowerCase(); 
          const fileName = filePath.split('/').pop();
          return (fileName.includes(trimmedSearchTerm.toLowerCase()) &&
            fileName !== "emptyfile.emptyfile" &&
            fileName.length > 0
          );
        });
  
        const newCurrentItems = filteredItems.map((item) => ({
          ...item,
          path: item.name,
          name: item.name,
          location: item.name.split("/").slice(0, -1).pop(),
          isFolder: item.folder,
        }));
        handleFileSearch(newCurrentItems);
      }
      setMatchingSuggestions([]);
      dispatch(setSearchClose(true));
    };
  
    const handleChange = (e) => {
      const value = e.target.value;
      dispatch(setSearchTerm(value.trim()));
  
      if (value.length > 0) {
        const filteredSuggestions = suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
        setMatchingSuggestions(filteredSuggestions);
      } else {
        setMatchingSuggestions([]);
      }
    };
  
    useEffect(() => {
      const fileSuggestions = Array.from(new Set(files.map((file) => {
        const filePath = file.name.trim();
        const fileName = filePath.split('/').pop();
        return fileName;
      }).filter(fileName => fileName !== "emptyfile.emptyfile")));
      setSuggestions(fileSuggestions);
    }, [files]);
  
    const handleSuggestionClick = (suggestion) => {
      dispatch(setSearchTerm(suggestion.trim()));
      setMatchingSuggestions([]);
      dispatch(setSearchClose(true));
      fileSearch(suggestion.trim());
    };
  
    return (
      <div className="header1-search">
        <SearchIcon onClick={handleSearchIcon} />
        <input type="text"
          placeholder="Search..."
          ref={inputRef} 
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={handleSearchInputKeyDown}
        />
        {searchClose &&
          <ClearIcon fontSize="small" onClick={handleClearSearch}  />
        }
        {matchingSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {matchingSuggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className={index === highlightedIndex ? 'highlighted' : ''}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }