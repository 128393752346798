// export const RENAME_API_URL = "https://devapi.ez-byte.com:5004/rename";

export const RENAME_API_URL = process.env.REACT_APP_RENAME_API_URL;

//  "https://devapi.ez-byte.com:5004/rename"

export default async function RenameService(renameData, accessToken) {
  console.log(`Rename DATA IN RENAME SERVICE`);
  console.log(renameData);

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${accessToken}`);

  // const renamePayload = JSON.stringify({
  //   renameFileList: renameData.map((file) => ({
  //     currentName: file.currentName,
  //     newName: file.newName,
  //   })),
  // });

  const renamePayload = JSON.stringify({
    renameFileList: renameData.map((file) => {
      let newName;
      if (file.isFolder) {
        newName = file.newName.endsWith("/")
          ? file.newName
          : `${file.newName}/`;
      } else {
        newName = file.newName;
      }

      return {
        currentName: file.currentName,
        newName: newName,
      };
    }),
  });

  console.log(`Rename PAYLOAD BEFORE SENDING`);
  console.log(renamePayload);
  console.log("Headers In Rename:", Object.fromEntries(headers.entries()));

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: renamePayload,
  };

  try {
    const response = await fetch(`${RENAME_API_URL}`, requestOptions);
    if (response.ok) {
      const result = await response.text();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Fetch error:", error);
    return false;
  }
}
