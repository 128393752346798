import React, { useEffect, useState } from "react";
import "../newComponent/RenameDialog.css";
import { message } from "antd";

const RenameDialog = ({ open, onClose, onRename, fileName }) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  console.log(fileName);
  // Extract only the file name to display in the input field
  const initialFileName = fileName.split("/").pop(); // Get the last part (file name)
  const [newName, setNewName] = useState(initialFileName);

  // Update the newName state whenever fileName prop changes
  useEffect(() => {
    setNewName(initialFileName);
  }, [fileName]);

  // const handleRename = () => {
  //   onRename(newName); // Send only the new name, without the extension
  //   setNewName("");
  //   onClose();
  // };

  const handleRename = () => {
    // Validate that the input is not empty
    if (!newName.trim()) {
      messageApi.open({
        type: "warning",
        content: "Field should not be empty",
        duration: 3,
      });
      return; // Prevent renaming if the field is empty
    }

    onRename(newName); // Send only the new name, without the extension
    setNewName("");
    onClose();
  };

  const handelKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRename();
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  if (!open) return null; // Only render the dialog if it's open

  return (
    <div className="dialog-overlay">
      {messageContextHolder}
      <div className="dialog-container">
        <div className="dialog-title">Rename</div>
        <div className="dialog-content">
          <input
            autoFocus
            type="text"
            className="dialog-input"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="New Name"
            onKeyDown={handelKeyDown}
          />
        </div>
        <div className="dialog-actions">
          <button onClick={onClose} className="dialog-button">
            Cancel
          </button>
          <button
            onClick={handleRename}
            className="dialog-button dialog-button-confirm"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenameDialog;
