import React, { useState, createContext, useContext, useEffect } from "react";
import { auth, db } from "../config/firebase";

// import { doc, getDoc } from "firebase/firestore";

import { getFirestore, doc, getDoc } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

// const UserContext = createContext();

// export function useUser() {
//   return useContext(UserContext);
// }

// export const UserProvider = ({ children }) => {
//   const [userDetail, setUserDetail] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const auth = getAuth();
//   const db = getFirestore();

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged(async (user) => {
//       setLoading(true); // Set loading to true at the start of the auth check
//       if (user) {
//         try {
//           const docRef = doc(db, "Users", user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             setUserDetail(docSnap.data());
//           } else {
//             setUserDetail(null); // Clear userDetail if no data found
//             setError("User data not found.");
//           }
//         } catch (err) {
//           setUserDetail(null);
//           setError("Failed to fetch user data.");
//           console.error("Error fetching user data:", err);
//         }
//       } else {
//         setUserDetail(null); // Clear userDetail if user is not authenticated
//         setError("No user is signed in.");
//       }
//       setLoading(false); // Set loading to false after processing
//     });

//     return () => unsubscribe();
//   }, [auth, db]);

//   return (
//     <UserContext.Provider value={{ userDetail, loading, error }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// const UserContext = createContext();

// export function useUser() {
//   return useContext(UserContext);
// }

// export const UserProvider = ({ children }) => {
//   const [userDetail, setUserDetail] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged(async (user) => {
//       if (user) {
//         try {
//           const docRef = doc(db, "Users", user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             setUserDetail(docSnap.data());
//           } else {
//             setError("User data not found.");
//           }
//         } catch (err) {
//           setError("Failed to fetch user data.");
//           console.error("Error fetching user data:", err);
//         }
//       } else {
//         setError("No user is signed in.");
//         setUserDetail(null);
//         //  mark
//       }
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

//   return (
//     <UserContext.Provider value={{ userDetail, loading, error }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// // export function useUser() {
// //   return React.useContext(UserContext);
// // }

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserDetail(docSnap.data());
          } else {
            setError("User data not found.");
          }
        } catch (err) {
          setError("Failed to fetch user data.");
          console.error("Error fetching user data:", err);
        }
      } else {
        setError("No user is signed in.");
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Clean up on unmount
  }, []);

  return (
    <UserContext.Provider value={{ userDetail, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
