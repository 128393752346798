import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchRefresh: false,
  searchTerm: '',
  searchClose: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setSearchClose(state, action) {
      state.searchClose = action.payload;
    },
    setSearchRefresh(state, action) {
      state.searchRefresh = action.payload;
    },
  },
});

export const { setSearchTerm, setSearchClose, setSearchRefresh} = searchSlice.actions;
export default searchSlice.reducer;