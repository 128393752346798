import React, { useState, useEffect } from "react";
import { AiOutlineDownload, AiOutlineClose, AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import axios from "axios";
import "./FilePreview.css";
import FileViewer from "react-file-viewer";

const READ_API_HOST_URL = process.env.REACT_APP_READ_API_URL;

export default function FilePreview({ fileName, fileIndex, allFiles, onClose, accessToken }) {
  const getFilePath = (file) => {
    return file.path ? file.path : file.name;
  };
  const [currentFileIndex, setCurrentFileIndex] = useState(fileIndex);
  const [currentFileName, setCurrentFileName] = useState(allFiles[fileIndex].name);
  const [currentFilePath, setCurrentFilePath] = useState(getFilePath(allFiles[fileIndex]));
  const [fileUpdated, setFileUpdated] = useState(false);
  const [disablePrev, setDisablePrev] = useState(fileIndex === 0);
  const [disableNext, setDisableNext] = useState(fileIndex === allFiles.length - 1);
  const [isFileSupported, setIsFileSupported] = useState(true);
  const [blobUrl, setBlobUrl] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [isTextFile, setIsTextFile] = useState(false);

  useEffect(() => {
    console.log("FilePreview1: ", currentFileIndex, currentFileName, currentFilePath, allFiles[currentFileIndex]);
  }, []);

  useEffect(() => {
    console.log("FilePreview2: ", currentFileIndex, currentFileName, currentFilePath, allFiles[currentFileIndex]);
  }, [currentFileIndex]);


  const handleArrow = (i) => {
    setCurrentFileIndex((indx) => {
      const file = allFiles[indx + i];
      setCurrentFileName(file.name);
      setCurrentFilePath(getFilePath(file));
      setFileUpdated(false);
      setIsFileSupported(true);
      //setBlobUrl(null);
      setTextContent(null);
      setIsTextFile(false);
      return indx + i;
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!disablePrev && event.key === "ArrowLeft") {
        handleArrow(-1);
      }
      if (!disableNext && event.key === "ArrowRight") {
        handleArrow(1);
      }
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [disablePrev, disableNext, onClose]);

  useEffect(() => {
    setDisablePrev(currentFileIndex === 0);
    setDisableNext(currentFileIndex === allFiles.length - 1);
  }, [currentFileIndex]);

  useEffect(() => {
    const removePrefix = (filePath) => {
      return filePath.startsWith("/") ? filePath.slice(1) : filePath;
    };

    const fetchFile = async () => {
      try {
        const filePath = `${READ_API_HOST_URL}/getFileContent?name=${removePrefix(currentFilePath)}&kk=${Math.random()}`;
        const response = await axios.get(filePath, {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });

        const type = getFileExtension(currentFilePath);

        if (type === "txt" || type === "log" || response.data.type === 'text/plain') {
          setTextContent(await response.data.text());
          setIsTextFile(true);
        } else {
          setIsTextFile(false);
          const blob = new Blob([response.data], { type: response.data.type });
          const url = URL.createObjectURL(blob);
          setBlobUrl(url);
          //return () => URL.revokeObjectURL(url); // Clean up the URL object
        }

        if (type === "xls" || type === "xlsx") {
          setIsFileSupported(false);
        } else {
          setIsFileSupported(true);
        }
      } catch (err) {
        setIsFileSupported(false);
      }
      setFileUpdated(true);
    };

    fetchFile();
  }, [currentFileIndex, accessToken]);

  const getFileExtension = (filePath) => {
    return filePath.split(".").pop();
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = currentFileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!currentFilePath) {
    return <div>Error: File path is not defined.</div>;
  }

  if (!blobUrl && !isTextFile) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`overlay ${fileName ? "active" : ""}`}>
      <div className="overlay-content">
        <div className="HeaderContainer">
          <div className="HeaderTitle">{currentFileName}</div>
          <div className="HeaderClose">
            <AiOutlineClose className="close-btn" onClick={onClose} />
          </div>
        </div>
        <div className="preview">
          <div className="leftArrow-icon-container">
            {!disablePrev && (
              <AiFillCaretLeft
                className="leftArrow"
                onClick={() => handleArrow(-1)}
              />
            )}
          </div>
          <div className="previewContent custom-scrollbar">
            {!fileUpdated && <div id="previewLoading">Loading...</div>}
            {fileUpdated && (
              <>
                {isTextFile ? (
                  <textarea
                    style={{ width: "100%", height: "100%", backgroundColor: "white", overflowY: "auto", border: "0px", resize: "none" }}
                    disabled
                    value={textContent}
                    aria-label="Text file content"
                  />
                ) : isFileSupported ? (
                  <FileViewer
                    fileType={getFileExtension(currentFilePath)}
                    filePath={blobUrl}
                    onError={() => setIsFileSupported(false)}
                    style={{ height: "100%" }}
                  />
                ) : (
                  <div className="unSupportedFile HeaderTitle">
                    <div>
                      Preview not available for this file.&nbsp;
                      <br />
                      Please download this file.&nbsp;
                      <br />
                    </div>
                    <div>
                      <a href="#" onClick={handleDownload} className="downloadFileIcon">
                        <AiOutlineDownload className="downloadFileIcon" />
                      </a>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="rightArrow-icon-container">
            {!disableNext && (
              <AiFillCaretRight
                className="rightArrow"
                onClick={() => handleArrow(1)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
