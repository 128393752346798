import axios from "axios";
export const DELETE_API_URL = process.env.REACT_APP_DELETE_API_URL;

export default async function DeleteService(deleteData, accessToken) {
  console.log(deleteData);
  // let files = deleteData.map((file) =>
  //   file.path.startsWith("/") ? file.path.slice(1) : file.path
  // );

  let files = deleteData.map((file) => {
    let filePath = file.path.startsWith("/") ? file.path.slice(1) : file.path;
    if (file.isFolder && !filePath.endsWith("/")) {
      filePath = `${filePath}/`;
    }
    return filePath;
  });

  console.log(files);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  const deletePayload = {
    fileWithPathList: files,
  };

  console.log(`DELETE PAYLOAD`);
  console.log(deletePayload);
  console.log("Headers In Delete:", headers);

  try {
    const response = await axios.post(`${DELETE_API_URL}`, deletePayload, {
      headers: headers,
    });

    if (response.status === 200) {
      const result = response.data;
      //console.log(result);
      return true;
    } else {
      //console.error('Request failed with status:', response.status);
      return false;
    }
  } catch (error) {
    //console.error('Request error:', error);
    return false;
  }
}
