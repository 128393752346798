export const CUT_API_URL = process.env.REACT_APP_CUT_API_URL;

export default async function CutServiceForDragAndDrop(cutData, accessToken) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${accessToken}`);

  console.log(JSON.stringify({ moveFileList: cutData }));

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ moveFileList: cutData }),
  };

  try {
    const response = await fetch(`${CUT_API_URL}`, requestOptions);

    if (response.ok) {
      const result = await response.text();
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error during cut operation:", error);
    return false;
  }
}
